.App-header {
  background-color: #282c34;
  min-height: 40px;
  display: flex;
  flex-direction: row;
  align-items: initial;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 6pt;
}

.calc-header {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #282c34;
  margin: auto;
  margin-top: .5em;
}

.App-link {
  color: #61dafb;
}

.App-header a {
  text-decoration: none;
  color: white;
}

.App-header a:hover {
  text-decoration: none;
  color: white;
}

.container .Calc {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; 
  width: 95%;
}

@media(max-width: 575px) {
  .container .Calc {
    width: 100%;
    border-radius: 0px; 
    box-shadow: none;
  }
}

.introduction {
  padding: 0 20%;
}

.introduction #modalClose {
  text-align: center;
}

nav.main {
  padding-left: 15pt;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  height: calc(100vh - 40px);
  
}

nav.main h1 {
 padding-top: 0.5em;
}

nav.main ul {
  padding: 0;
}

nav li {
  list-style: none;
}

.app-side {
  background-color: rgb(224, 225, 226);
  margin-right: calc(var(--bs-gutter-x) * .5);
  padding-bottom: calc(var(--bs-gutter-x) * .5);
}

.unit-costs-apps {
  background-color: white;
  padding-bottom: 1em;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

@media (max-width: 412px) {
  .styled-table th, .styled-table td {
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: top;
}
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.warning {
  color: darkred;
  font-weight: bold;
}

.warning-header {
  color: yellow;
  font-weight: bold;
}

.item-title {
  text-transform: capitalize;
}

/* Grand Total */
.app-wrapper {
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  position: sticky;
  bottom: 0;
}

.grand-total {
  border: 2px solid;
  border-color: orange; /* Default border color */
}
@media (max-width: 570px) {
  .grand-total-narrow {
    border: 2px solid;
    border-color: orange; /* Default border color */
  }
}

p.grandTotalHeading {
  margin-bottom: 0;
}

.best {
  border-color: green;
}

.horizontal-submenu {
  list-style-type: none;
}

.horizontal-submenu li {
  display: inline;
}

.horizontal-submenu li::after {
  content: " | "
}

.horizontal-submenu li:last-child:after {
  content: ""
}

.parentListItem {
  list-style: none;
}

.emoji {
  text-align: center;
  font-size: xx-large;
}

.numbers {
  font-family: 'Monaco ','Lucida Console','Courier New', Courier, monospace;
}

li label {
  margin-bottom: 0.1rem;
  margin-left: .5rem;
}

#unit-cost-budget-table td.qtyChooser input {
 width: 6em;
}

.qtyChooser span.cost-type {
  display: block;
}

.qtyChooser, .monthlyTotal {
  text-align: right;
}